import gql from 'graphql-tag'


export const LIST_ORDER_PAYABLE = (templateType) => gql`query LIST_ORDER_PAYABLE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Payable (docType: $docType, q: $q) {
    id type code name remark billedAt
    orders {
      id total paid pending remaining
      account {
        id code name
      }
    }
  }
}`

export const LIST_ORDER_EXPENSE = (templateType) => gql`query LIST_ORDER_EXPENSE ($docType: String!, $contactId: Int!) {
  items: listDoc${templateType}OrderExpense (docType: $docType, contactId: $contactId) {
    id name isDefault
  }
}`

export const LIST_ORDER_WITHHOLDING_PRICE = (templateType) => gql`query LIST_ORDER_WITHHOLDING_PRICE ($docType: String!, $contactId: Int!) {
  items: listDoc${templateType}OrderWithholdingPrice (docType: $docType, contactId: $contactId) {
    id name isDefault
  }
}`

export const LIST_ORDER_PAYMENT = (templateType) => gql`query LIST_ORDER_PAYMENT ($docType: String!, $contactId: Int!) {
  items: listDoc${templateType}OrderPayment (docType: $docType, contactId: $contactId) {
    id name isDefault
  }
}`

export const LIST_ORDER_INVOICE = (templateType) => gql`query LIST_ORDER_INVOICE ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableInvoice (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice dueAt remaining
    invoice {
      id type code name contactId
      approvedBy closedBy
    }
    salesorder {
      id type code name contactId
    }
  }
}`

export const LIST_ORDER_BAD_DEBT = (templateType) => gql`query LIST_ORDER_BAD_DEBT ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableBadDebt (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    badDebt {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

export const LIST_ORDER_DEBIT_NOTE = (templateType) => gql`query LIST_ORDER_DEBIT_NOTE ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableDebitNote (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    debitNote {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

export const LIST_ORDER_REDUCE_DEBT = (templateType) => gql`query LIST_ORDER_REDUCE_DEBT ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableReduceDebt (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    reduceDebt {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

export const LIST_ORDER_DEPOSIT = (templateType) => gql`query LIST_ORDER_DEPOSIT ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableDeposit (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice remaining
    deposit {
      id type code name contactId
      approvedBy closedBy
    }
  }
}`

const detailResponse = `
  id type name
  qty price totalPrice
  unitId unit {id name}
  docId doc {id code}
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  parentId parent {
    id
    docId doc {
      id type code approvedAt closedAt
    }
    parentId parent {
      id
      docId doc {
        id type code approvedAt closedAt salesCredit
      }
    }
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  order: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id docId parentId
  }
}`

export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_WITHHOLDING_PRICE = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderWithholdingPrice (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_PAYMENT = (templateType) => gql`mutation CREATE_ORDER_PAYMENT ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderPayment (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_INVOICE = (templateType) => gql`mutation CREATE_ORDER_INVOICE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}OrderInvoice (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id
  }
}`

export const CREATE_ORDER_BAD_DEBT = (templateType) => gql`mutation CREATE_ORDER_BAD_DEBT ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}OrderBadDebt (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id
  }
}`

export const CREATE_ORDER_DEBIT_NOTE = (templateType) => gql`mutation CREATE_ORDER_DEBIT_NOTE ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}OrderDebitNote (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id
  }
}`

export const CREATE_ORDER_REDUCE_DEBT = (templateType) => gql`mutation CREATE_ORDER_REDUCE_DEBT ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}OrderReduceDebt (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id
  }
}`

export const CREATE_ORDER_DEPOSIT = (templateType) => gql`mutation CREATE_ORDER_DEPOSIT ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  order: createDoc${templateType}OrderDeposit (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id docId
  }
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {
    id
  }
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`