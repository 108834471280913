import gql from 'graphql-tag'


const listResponse = `
  id type code name remark migrationNote
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
  contactId contact {id type branch}
  relevance {
    payments {id type code}
  }
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType} (docType: $docType, q: $q) {${listResponse}}
}`

export const LIST_PAYABLE = (templateType) => gql`query LIST_PAYABLE ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType}Payable (docType: $docType, q: $q) {
    id type code name remark contactId billedAt
    orders {
      id total paid pending remaining
      account {
        id code name
      }
    }
  }
}`

export const WATCH_DOCS_CREATED = (templateType) => gql`subscription WATCH_DOCS_CREATED ($docType: String!) {
  docCreated: watchDoc${templateType}Created (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_UPDATED = (templateType) => gql`subscription WATCH_DOCS_UPDATED ($docType: String!) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_DESTROYED = (templateType) => gql`subscription WATCH_DOCS_DESTROYED ($docType: String!) {
  docDestroyed: watchDoc${templateType}Destroyed (docType: $docType) {id}
}`

const detailResponse = `
  id type code name remark contactId billedAt
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
`

export const DETAIL_DOC = (templateType) => gql`query DOC ($docType: String!, $docId: Int!) {
  doc: detailDoc${templateType} (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_DOC_UPDATED = (templateType) => gql`subscription WATCH_DOC_UPDATED ($docType: String!, $docId: Int) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const CREATE_DOC = (templateType) => gql`mutation CREATE_DOC ($docType: String!, $input: Doc${templateType}Input!) {
  doc: createDoc${templateType} (docType: $docType, input: $input) {id}
}`

export const UPDATE_DOC = (templateType) => gql`mutation UPDATE_DOC ($docType: String!, $docId: Int!, $input: Doc${templateType}Input!) {
  doc: updateDoc${templateType} (docType: $docType, docId: $docId, input: $input) {id}
}`

export const DESTROY_DOC = (templateType) => gql`mutation DESTROY_DOC ($docType: String!, $docId: Int!) {
  doc: destroyDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const APPROVE_DOC = (templateType) => gql`mutation APPROVE_DOC ($docType: String!, $docId: Int!, $approvedDate: Date!) {
  doc: approveDoc${templateType} (docType: $docType, docId: $docId, approvedDate: $approvedDate) {id}
}`

export const CANCEL_DOC = (templateType) => gql`mutation CANCEL_DOC ($docType: String!, $docId: Int!) {
  doc: cancelDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const CLOSE_DOC = (templateType) => gql`mutation CLOSE_DOC ($docType: String!, $docId: Int!, $closedDate: Date!) {
  doc: closeDoc${templateType} (docType: $docType, docId: $docId, closedDate: $closedDate) {id}
}`

export const ROLLBACK_DOC = (templateType) => gql`mutation ROLLBACK_DOC ($docType: String!, $docId: Int!) {
  doc: rollbackDoc${templateType} (docType: $docType, docId: $docId) {id}
}`

export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($docType: String!, $q: FilterInput) {
  contacts: listDoc${templateType}Contact (docType: $docType, q: $q) {
    id type code name branch isDefault
    configs {id type isDefault}
    addresses {
      id type name attendance
      address addressTag
      telephone mobile fax email lineApp
      isDefault
      logisticId logistic {id code name}
    }
  }
}`

export const LIST_RECEIVABLE = (templateType) => gql`query LIST_RECEIVABLE ($docType: String!, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailableInvoice (docType: $docType, q: $q) {
    id totalPrice dueAt remaining
    invoice {
      id type code name remark contactId
    }
    salesorder {
      id type code name
    }
  }
}`
