<template>
  <sgv-table
    ref="docList"
    :rKey="rKey"
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :class="{'disabled': !$auth.hasRole(`doc:${docType}:add`)}"
            :to="toDetail(0, item.invoice.contactId)">
            {{item.invoice.code}}
          </router-link>

          <div class="">
            <small class="text-info">
              {{ item.salesorder.code }}
            </small>
          </div>
          <div class="">
            <small
              :class="{
                'text-danger': isDue(item.dueAt),
                'text-primary': !isDue(item.dueAt)
              }">
              {{ item.dueAt | date }}
            </small>
          </div>
        </td>
        <td v-if="hidden.name">
          {{item.invoice.name}}
          <div class="">
            <small class="mr-2">
              ค้างชำระ:
              <span class="text-danger">
                {{item.remaining | comma}}
              </span>
            </small>
            <small>
              ยอดรวม:
              <span class="text-success">
                {{item.totalPrice | comma}}
              </span>
            </small>
          </div>
          <div v-if="item.invoice.remark">
            <small class="text-primary">
              {{ item.invoice.remark }}
            </small>
          </div>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`doc:${docType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_RECEIVABLE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReceivable`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'รายละเอียด', value: 'name'}
      ],
      filter: {
        limit: 0,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter'],
      orders: [],
      today: new Date()
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_RECEIVABLE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    isDue (dueAt) {
      return this.today >= new Date(dueAt)
    },
    getFilter (v) {
      return {
        params: {
          search: v.params ? v.params.search : ''
        }
      }
    },
    toDetail (id, contactId) {
      return {
        name: this.detailView,
        params: {docId: id},
        query: {contactId}
      }
    },
    getSummerize (item) {
      return item.orders.reduce((t,v) => {
        t.total -= v.total
        return t
      }, {total: 0})
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
